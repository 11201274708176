/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import dateformat from "dateformat";
import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom-v6';
// @ts-ignore
import { Button, Checkbox, CurrencyInput, Select, TextInput, CheckboxGroup, DropdownMenu, DropdownTrigger, DropdownMenuContent, RadioGroup, ErrorSummary } from '@ux/balance-react';
// @ts-ignore
import { IconDownload, IconSearch, IconTrash, IconRefresh } from '@ux/balance-icons';
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import Lockbox from '../API_Integrations/Lockbox';
import LockboxesTransactions from '../API_Integrations/LockboxesTransactionSearch';
import { LockboxTransactionSearchData, NavToggle, OperationOptions, SearchColumnData, TransactionDetailOptions, TransactionOptionsLength, TransactionCurrentPage, BatchDetailRes, batch_ID, transaction_ID, userState } from '../atom';
import { ILockbox_options } from '../interfaces/ICorrespondence';
import { ILockbox, ILockbox_req_body } from '../interfaces/iLockBox';
import { ILockboxesTransactionSearch_req_body, InitialTransactionsEnum, InitialColumnsEnum, ILockboxesTransactionSearch, IDetailKeyRes, ICustomFields, IDetailData, IResWithAdditionalData, IColumnOptions } from '../interfaces/ILockboxesTransactionsSearch';
import { deleteReport, downloadReport, getReports, Report } from '../API_Integrations/reports';
import BatchdataDetail from '../API_Integrations/BatchDetail';
import CalendarDateInput from '../components/CalendarDateInput';
import ClientHeader from '../components/ClientHeader';
import CustomModal from '../components/CustomModal';
import DateDisplay from '../components/DateDisplay';
import ModalsContainer from '../components/ModalsContainer';
import AdditionalOptionsSelection from '../components/SearchAndReporting/AddtionalOptionsSelection';
import SearchNavigationTabs from '../components/SearchAndReporting/SearchNavigationTabs';
import SearchSection from '../components/SearchAndReporting/SearchSection';
import Table from '../components/Table';
import Loader from '../components/Loader';
import { useDifferenceDate, ifStartNull, ifEndNull } from '../utils/use-date-calculator';
import { useTitle } from '../utils/use-title';
import { useFocusOnElemnt } from "../utils/use-focus-on-element";
import { useRepeatAnnouncement } from "../utils/use-repeat-live-announcement";
import { IDownloadObject } from '../interfaces/IBatchSummary';
import setCorpCodeAndDrowndownValues from "../utils/set-corp-code-and-dropdown-values";
import CorpCodes from '../API_Integrations/CorpCodes';
import { ICorpCodes } from '../interfaces/ICorpCodes';

interface KeyNameArray {
    [key: string]: any[]
}

interface ErrorMessage { ariaLabel: string, text: string, inputId: string }
interface CorpCode {  label: string, value: string, isDisabled?: boolean }

function SearchAndReports() {

    const userType = useRecoilValue(userState);
    const [loading, setLoding] = useState(false);
    useTitle("Search and reports");
    //Start search
    const [selectLockboxID, setselectLockboxID] = useState('');
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [selectBatch, setSelectBatch] = useState({ fromBatch: '', toBatch: '' });
    const [emptyLockboxID, setEmptyLockboxID] = useState(true);
    const [showValidationLockboxID, setShowValidationLockboxID] = useState(false);
    const [toggle, setToggle] = useRecoilState(NavToggle);
    const [lockbox_options, setLockbox_options] = useState<ILockbox_options[]>([]);
    const [corpcode, setCorpCode] = useState<{value: string, options: CorpCode[]}>({value: 'select corp code', options: []});
    const [data_transactions, setData_transactions] = useRecoilState(LockboxTransactionSearchData);
    const [disableAddTransaction, setDisableAddTransaction] = useState(false);
    const lockbox_list = [
        {
            isDisabled: true,
            label: 'Select',
            value: ''
        },
    ];
    const [transactionOptions, setTransactionOptions] = useRecoilState(TransactionDetailOptions);
    const [operationOptions, setOperationOptions] = useRecoilState(OperationOptions);
    const setTransactionId = useSetRecoilState(transaction_ID);
    const setTransaction_current_page = useSetRecoilState(TransactionCurrentPage);
    const setBatchId = useSetRecoilState(batch_ID);
    const setData_batch_detail = useSetRecoilState(BatchDetailRes);
    const [selectedTransactionID, setselectedTransactionID] = useState(0);
    const [showAdditionalSelection, setShowAdditionalSelection] = useState(false);
    const [checkedItems, setCheckedItems] = useState<{ [value: string]: boolean }>({});
    const [selectValues, setSelectValues] = useState<{ [key: string]: string }>({});
    const [textInputs, setTextInputs] = useState<{ [key: string]: { [key: number]: string } }>({});
    const [filterQuery, setFilterQuery] = useState('');
    const [detailFilterQuery, setDetailFilterQuery] = useState('');
    const [showValidationDateDiff, setShowValidationDateDiff] = useState(false);
    const [reportIdToDelete, setReportIdToDelete] = useState(0);
    const initialTextRef = useRef<HTMLHeadingElement | null>(null);
    const [isReportsReloading, setIsReportsReloading] = useState(false);
    const [transactionDetailsLoading, setTransactionDetailsLoading] = useState(false);
    const [detailKeysAdditional, setDetailKeysAdditional] = useState<IDetailKeyRes[]>([]);
    //checkbox    
    const [checkedAll, setCheckedAll] = useState(false);
    const [selectedRows, setSelectedRows] = useState(Array((data_transactions || []).length).fill(false));
    const [downloadObject, setDownloadObject] = useState<IDownloadObject>({ documents: false, checks: false, fileType: '' });
    const [transactionNotSelected, setTransactionNotSelected] = useState(false);
    const [errorMessagesSummary, seterrorMessagesSummary] = useState<ErrorMessage[]>([]);
    const [isvalidFile, setIsvalidFile] = useState(true);
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [liveAnnouncementText, setLiveAnnouncementText] = useState("");
    const errorSummaryRef = useRef<HTMLElement | null>(null);
    const initialTransactions = InitialTransactionsEnum;
    const initialColumns = InitialColumnsEnum;

    useFocusOnElemnt(initialTextRef);
    const navigate = useNavigate();

    const HandleAddTransactionList = () => {
        setShowAdditionalSelection(true);
    }
    const HandleDeleteTransactionList = (id: number) => {
        const newTransactions = transactionOptions.map(item =>
            item.id === id ? { ...item, isDeleted: !item.isDeleted } : item
        );
        setTransactionOptions(newTransactions);
    }
    const HandleOnChangeAdditionalSelection = (event: Event) => {
        const newVal = (event.target as HTMLInputElement).checked;
        const value = (event.target as HTMLInputElement).name;
        setCheckedItems((prevCheckedItems) => ({
            ...prevCheckedItems,
            [value]: newVal
        }));
    }
    const HandleAdditionalSelection = () => {
        const updatedTransactions = transactionOptions.map((item) => ({
            ...item,
            isDeleted: checkedItems[item.value] !== undefined ? !item.isDeleted : item.isDeleted
        }));
        setTransactionOptions(updatedTransactions);
        setCheckedItems({});
        setShowAdditionalSelection(false);
    }
    const HandleCancelSelection = () => {
        setShowAdditionalSelection(false);
    }
    const HandleTextInputChange = (event: Event, inputValue: string, index: number) => {
        const itemValue = (event.target as HTMLInputElement).value;
        setTextInputs(prevInputs => ({
            ...prevInputs,
            [inputValue]: {
                ...prevInputs[inputValue],
                [index]: itemValue
            }
        }));
    }
    const HandleOnChangeSelectOperation = (event: Event, inputValue: string) => {
        const Value = (event.target as HTMLInputElement).value;
        setSelectValues(prevValues => ({
            ...prevValues,
            [inputValue]: Value
        }));
        setTextInputs(prevInputs => ({
            ...prevInputs,
            [inputValue]: { 0: '', 1: '' }
        }));
    }
    useEffect(() => {
    }, [selectedTransactionID]);
    useEffect(() => {
        setDisableAddTransaction(false);
        setselectLockboxID('');
        setStartDate(new Date());
        setEndDate(new Date());
        setTransactionOptions(initialTransactions);
        setColumnData(initialColumns);
        setData_transactions([]);
        setSelectedColumn(() => {
            const initialValue: { [index: number]: string } = {};
            initialColumns.forEach(item => {
                if (item.isIncluded) {
                    initialValue[item.id] = item.value;
                }
            });
            return initialValue;
        });
        const fetchData = async () => {
            try {
                setLoding(true);
                transactionOptions.forEach(item => {
                    setSelectValues(prevValues => ({
                        ...prevValues,
                        [item.value]: 'select',
                    }));
                    setTextInputs(prevInputs => ({
                        ...prevInputs,
                        [item.value]: { 0: '', 1: '' }
                    }));
                });
                try {
                    const result = await getLockboxData({
                        lockboxNumber: '',
                        lockboxName: ''
                    });
                    getLockboxOptions(result);
                } catch (error) {
                    console.log('error lockbox API', error)
                }
                try {
                    await getReports().then(reports => setReports(reports));
                } catch (error) {
                    console.log('error get reports API', error)
                }

                if (userType.isBanker) {
                    let corpCodes: any[] = await CorpCodes.list();
                    let corpCodeNames: string[] = [];

                    corpCodes.forEach((item) => {
                        corpCodeNames.push(item.name); 
                    });
                
                    if (corpCodeNames && corpCodeNames.length > 1) {
                        let CorpCodeOptions = corpCodeNames.map((name) => {
                            let corpCode = corpCodes.find(corp => corp.name === name);
                            return {
                                label: name,
                                value: corpCode ? corpCode.code : name
                            };
                        });
                        setCorpCode({ value: 'select corp code', options: [...[{ label: 'Select', value: 'select corp code', isDisabled: true, }, { label: 'All Corp Codes', value: '' }], ...CorpCodeOptions] });
                    }
                }
                else {
                    setCorpCodeAndDrowndownValues(userType, setCorpCode);
                }

                setLoding(false)
            } catch (error) {
                console.log('error fetchData', error)
            }
        }
        fetchData();
    }, []);
    useEffect(() => {
    }, [lockbox_options]);
    useEffect(() => {
    }, [selectLockboxID]);
    const HandleOnChangeStartDate = (date: any) => {
        setStartDate(date);
    }
    const HandleOnChangeEndDate = (date: any) => {
        setEndDate(date);
    }
    const getAdditionalDetailsSetup = async (lockboxCode: string) => {
        const LockboxCode = lockboxCode;
        try {
            LockboxesTransactions.getAdditionalDetailKeys(LockboxCode).then(res => {
                setDetailKeysAdditional(res);
                if (res && res.length > 0) {
                    const additionalOptions = res.map((item, index) => {
                        return {
                            label: item.name,
                            value: item.name,
                            id: index + 4,
                            isDeleted: true,
                            type: item.keyType,
                            isInitial: false
                        }
                    });
                    setTransactionOptions([...initialTransactions, ...additionalOptions]);
                    [...initialTransactions, ...additionalOptions].forEach(item => {
                        setSelectValues(prevValues => ({
                            ...prevValues,
                            [item.value]: 'select',
                        }));
                        setTextInputs(prevInputs => ({
                            ...prevInputs,
                            [item.value]: { 0: '', 1: '' }
                        }));
                    });
                    const additionalColumnOptions = res.map((item, index) => {
                        return {
                            headerId: item.name,
                            headerLabel: item.name,
                            value: item.name,
                            label: item.name,
                            id: index + 10,
                            rowCellTableRowComponentsArrayIndex: 3,
                            type: 'component',
                            isIncluded: true,
                            isDisabled: false
                        }
                    });
                    setColumnData([...initialColumns, ...additionalColumnOptions]);
                    setPossibleColumnData([...initialColumns, ...additionalColumnOptions])
                    setSelectedColumn(() => {
                        const initialValue: { [index: number]: string } = {};
                        [...initialColumns, ...additionalColumnOptions].forEach(item => {
                            if (item.isIncluded) {
                                initialValue[item.id] = item.value;
                            }
                        });
                        return initialValue;
                    });
                } else {
                    setTransactionOptions(initialTransactions);
                    setColumnData(initialColumns);
                    setSelectedColumn(() => {
                        const initialValue: { [index: number]: string } = {};
                        initialColumns.forEach(item => {
                            if (item.isIncluded) {
                                initialValue[item.id] = item.value;
                            }
                        });
                        return initialValue;
                    });
                }
    
            }).finally(() => setTransactionDetailsLoading(false));
        } catch {
            console.log('item not found');
        }
    }
    const HandleOnChangeSelectLockboxId = (event: Event) => {
        setTransactionDetailsLoading(true);
        const lockboxCode = (event.target as HTMLInputElement).value;
        setselectLockboxID(lockboxCode);
        setData_transactions([]);
        getAdditionalDetailsSetup(lockboxCode); 
    }
    const HandleOnChangeSelectCorpCode = (event: Event) => {
        const corpCode = (event.target as HTMLInputElement).value;
        setCorpCode({...corpcode, value: corpCode});
    }
    const HandleOnChangeFromBatch = (event: Event) => { setSelectBatch({ ...selectBatch, fromBatch: (event.target as HTMLInputElement).value }) }
    const HandleOnChangeToBatch = (event: Event) => { setSelectBatch({ ...selectBatch, toBatch: (event.target as HTMLInputElement).value }) }

    //lockbox API
    const getLockboxData = async (ReqBody: ILockbox_req_body) => {
        let result = await Lockbox.listClientLockbox(ReqBody)
        if (result !== undefined) {
            return result;
        } else {
            console.log('item not found')
        }
    }
    const getLockboxOptions = (options: ILockbox[]) => {
        let LockboxOptions = options.map(lockbox => ({ label: lockbox.code, value: lockbox.code }));
        if (LockboxOptions.length === 1) {
            setTransactionDetailsLoading(true);
            setLockbox_options([...LockboxOptions]);
            setselectLockboxID(LockboxOptions[0].value);
            const lockboxCode = LockboxOptions[0].value;
            getAdditionalDetailsSetup(lockboxCode);
        } else {
            setLockbox_options([...lockbox_list, ...LockboxOptions]);
        }
    };
    //search
    const getLockboxesTransactions = async (ID: string, ReqBody: ILockboxesTransactionSearch_req_body) => {
        try {
            if (detailKeysAdditional.length > 0) {
                let result: IResWithAdditionalData[] | undefined = await LockboxesTransactions.listTransactions(ID, ReqBody)
                if (result !== undefined) {
                    const KeyNames = detailKeysAdditional.map(item => item.name)
                    const transformedResult = result.map(res => {
                        const AdditionalDetailsItems: KeyNameArray = {}
                        KeyNames.forEach(name => {
                            AdditionalDetailsItems[name] = []
                        });
                        const { id,
                            transactionId,
                            batchId,
                            itemSequenceNumber,
                            batchItemSequenceNumber,
                            processingDate,
                            processingDateFormatted,
                            amount,
                            amountFormatted,
                            routingNumber,
                            accountNumber,
                            serialNumber,
                            remitterName,
                            batchNumber } = res;
                        if (res.detailData && res.detailData.length > 0) {
                            res.detailData.forEach(data => {
                                if (data.fields && data.fields.length > 0) {
                                    data.fields.forEach(field => {
                                        detailKeysAdditional.forEach(key => {
                                            if (key.name === field.key) {
                                                AdditionalDetailsItems[field.key].push(field.value)
                                            }
                                        })
                                    });
                                }

                            })
                        }
                        delete res.detailData;
                        return { ...res, ...AdditionalDetailsItems }
                    });
                    setData_transactions(transformedResult);
                    setSelectedRows(Array(result.length).fill(false));
                    setCheckedAll(false);
                    if (result && result.length > 1) {
                        setLiveAnnouncementText(`${result.length} results found.`);
                    } else if (result && result.length === 1) {
                        setLiveAnnouncementText(`${result.length} result found.`);
                    } else {
                        setLiveAnnouncementText("No result found. Please set search preferences and try again.");
                    }
                } else {
                    console.log('item not found')
                }
            } else {
                let result = await LockboxesTransactions.listTransactions(ID, ReqBody)
                if (result !== undefined) {
                    setData_transactions(result);
                    setSelectedRows(Array(result.length).fill(false));
                    setCheckedAll(false);
                    if (result && result.length > 1) {
                        setLiveAnnouncementText(`${result.length} results found.`);
                    } else if (result && result.length === 1) {
                        setLiveAnnouncementText(`${result.length} result found.`);
                    } else {
                        setLiveAnnouncementText("No result found. Please set search preferences and try again.");
                    }
                } else {
                    console.log('item not found')
                }
            }


        } catch (error) {
            console.log(error);
        } finally {
            setLoadingSearch(false);
        }
    }
    useEffect(() => {
        let filters: string[] = [];

        transactionOptions.filter(item => !item.isDeleted && item.isInitial).forEach(item => {
            var itemName = item.value.charAt(0).toUpperCase() + item.value.slice(1);

            if (selectValues[item.value] === 'range') {
                filters.push(`${itemName}>=${textInputs[item.value]?.[0]?.replace(',', '') || ''},${itemName}<=${textInputs[item.value]?.[1]?.replace(',', '') || ''}`);
            } else if (selectValues[item.value] !== 'select') {
                filters.push(`${itemName} ${selectValues[item.value]} ${textInputs[item.value]?.[0]?.replace(',', '') || ''}`);
            }
        });
        if(selectBatch.fromBatch !== '' && selectBatch.toBatch !== '') {
            const BatchFrom= Number(selectBatch.fromBatch);
            const BatchTo = Number(selectBatch.toBatch);
            filters.push(`batchNumber >= ${BatchFrom}, batchNumber <= ${BatchTo}`);
        } else if(selectBatch.fromBatch !== '' && selectBatch.toBatch === '') {
            const BatchFrom= Number(selectBatch.fromBatch);
            filters.push(`batchNumber >= ${BatchFrom}`);
        } else if(selectBatch.fromBatch === '' && selectBatch.toBatch !== '') {
            const BatchTo = Number(selectBatch.toBatch);
            filters.push(`batchNumber <= ${BatchTo}`);
        }
        let detailsFilters: string[] = [];
        transactionOptions.filter(item => !item.isDeleted && !item.isInitial).forEach(item => {
            var itemName = item.value.charAt(0).toUpperCase() + item.value.slice(1);

            if (selectValues[item.value] === 'range') {
                detailsFilters.push(`${itemName}>=${textInputs[item.value]?.[0]?.replace(',', '') || ''},${itemName}<=${textInputs[item.value]?.[1]?.replace(',', '') || ''}`);
            } else if (selectValues[item.value] !== 'select') {
                detailsFilters.push(`${itemName} ${selectValues[item.value]} ${textInputs[item.value]?.[0]?.replace(',', '') || ''}`);
            }
        });

        setFilterQuery(filters.length > 0 ? filters.join(',') : '');
        setDetailFilterQuery(detailsFilters.length > 0 ? detailsFilters.join(',') : '');
    }, [selectValues, textInputs, transactionOptions, selectBatch]);

    let announcement = "Loading, please wait.";
    const repeatLoadingAnnouncement = () => {
        setLiveAnnouncementText(announcement);
        announcement += '.';
    };

    useRepeatAnnouncement({ repeatLoadingAnnouncement, loadingSearch });
    const HandleLockboxTransactionSearch = () => {
        const isNotvalid: boolean = useDifferenceDate(startDate, endDate);
        let lockbox_selected = selectLockboxID;
        let StartDate = startDate !== null ? dateformat(startDate, "yyyy-mm-dd") : dateformat(ifStartNull(endDate), "yyyy-mm-dd");;
        let EndDate = endDate !== null ? dateformat(endDate, "yyyy-mm-dd") : dateformat(ifEndNull(startDate), "yyyy-mm-dd");
        if (isNotvalid) {
            setShowValidationDateDiff(true);
            return;
        }
        if (lockbox_selected === '') {
            setShowValidationLockboxID(true);
            return
        }

        setLoadingSearch(true);
        setLiveAnnouncementText("Loading, please wait.");
        let body: { startDate?: string, endDate?: string, filters?: string, detailFilters?: string, corpCode?: string } = { startDate: StartDate, endDate: EndDate };
        if (filterQuery !== '') {
            body.filters = filterQuery;
        }
        if (detailFilterQuery !== '') {
            body.detailFilters = detailFilterQuery;
        }
        if((corpcode.value !== '') && (corpcode.value !== 'select corp code')) {
            body.corpCode = corpcode.value;
        }
        getLockboxesTransactions(lockbox_selected, body);
        setToggle(3);
    }

    useEffect(() => {
        const keyDownHandler = (e: KeyboardEvent) => {
            const searchForm = document.getElementById('search-and-reports-form') as HTMLFormElement | null;
            const targetedElement = document.activeElement as HTMLElement;
            if (e.key === 'Enter') {
                e.preventDefault();
                if (searchForm && (targetedElement.classList.contains('search-and-reports-button') || targetedElement.tagName === 'BODY')) {

                    HandleLockboxTransactionSearch();

                }
                if ((targetedElement.tagName === 'A' || targetedElement.tagName === 'BUTTON')) {
                    targetedElement.click();
                }
            }
        };
        document.addEventListener('keydown', keyDownHandler as EventListener);
        return () => {
            document.removeEventListener('keydown', keyDownHandler as EventListener);
        };
    }, [HandleLockboxTransactionSearch]);
    useEffect(() => {
    }, [transactionOptions]);
    //Column
    const [columnData, setColumnData] = useRecoilState(SearchColumnData);
    const[possibleColumns, setPossibleColumnData] =useState<IColumnOptions[]>([]);
    const [selectedColumn, setSelectedColumn] = useState<{ [index: number]: string }>(() => {
        const initialValue: { [index: number]: string } = {};
        columnData.forEach(item => {
            if (item.isIncluded) {
                initialValue[item.id] = item.value;
            }
        });
        return initialValue;
    });
    const [selectedItemId, setSelectedItemId] = useState<null | number>(null);
    const [showAdditionalColumnSelection, setShowAdditionalColumnSelection] = useState(false);
    const [checkedColumnItems, setCheckedColumnItems] = useState<{ [value: string]: boolean }>({});
    useEffect(() => {
        console.log(selectedItemId);
    }, [selectedItemId]);
    const HandleDeleteColumnList = (selected_id: number | null, item_id: number, index:number) => {

        if (index !== null) {
            setColumnData((prevColumnData)=>

                prevColumnData.map(item=> {if (item.value === prevColumnData[index].value) {

                    
                    return { ...item, isIncluded: false, isDisabled: true }
                }
                    return item; 
                }));
            setPossibleColumnData(prevColumnData =>prevColumnData.map(item => {
                if (item.id === item_id) {

                    
                    return { ...item, isIncluded: false, isDisabled: true }
                }
                return item; 
            }));
            setColumnData((prevColumnData)=>{
            
                var deletedItem = [...prevColumnData][index];               
                var newColumnData = [...prevColumnData];
               
                if(deletedItem)
                {             
                     newColumnData = newColumnData.filter(column=> column.value !==deletedItem.value);
                     newColumnData.push(deletedItem);
                    return newColumnData;
                }
                return prevColumnData;
            })
          
        } else {
        
            setPossibleColumnData(prevColumnData =>prevColumnData.map(item => {
                if (item.id === item_id) {

                    
                    return { ...item, isIncluded: false, isDisabled: true }
                }
                return item; 
            }));
            setColumnData((prevColumnData)=>{
                const deletedItem = prevColumnData.find(item=>item.id===item_id);
                
                var newColumnData = [...prevColumnData];
                if(deletedItem)
                {
                     newColumnData = newColumnData.filter(column=> column.value !==deletedItem.value);
                     newColumnData.push(deletedItem);
                    return newColumnData;
                }
                return prevColumnData;
            })
        }
        setSelectedItemId(null);
        setColumnData(prevColumnData => prevColumnData.filter(column => column.id !== item_id));
        setPossibleColumnData(prevPossibleColumns => prevPossibleColumns.filter(column => column.id !== item_id));

    }
    const HandleOnChangeSelectColumn = (event: Event, i: number, index: number) => {
        const itemValue = (event.target as HTMLInputElement).value;
        const itemID = (event.target as HTMLInputElement).id;
        var sortedColumnData = [...columnData];
       
         
        const correspondingItem = columnData.find(item => item.value === itemValue);
        if (correspondingItem) {
            setSelectedItemId(correspondingItem.id);
        } else {
            setSelectedItemId(null);
        }
       
        setColumnData(prevColumnData=>{
            var columnRecord = prevColumnData.find(item=> item.value === itemValue);
            
            if(columnRecord)
            {
             var incomingIndex = prevColumnData.indexOf(columnRecord)
             var outgoingRecord = sortedColumnData[index];
             sortedColumnData[index] = columnRecord;
             sortedColumnData[incomingIndex] = outgoingRecord;
             return sortedColumnData;
            }
            return prevColumnData;
        });

    }
    const HandleOnChangeAddColumnSelection = (event: Event) => {
        const newVal = (event.target as HTMLInputElement).checked;
        const value = (event.target as HTMLInputElement).name;
        setCheckedColumnItems((prevCheckedItems) => ({
            ...prevCheckedItems,
            [value]: newVal
        }));
    }
    const HandleAddColumnSelection = () => {
        const updatedColumns = columnData.map((item) => {
            if (checkedColumnItems[item.value]) {
                return { ...item, isIncluded: true, isDisabled: false }
            }
            return item;
        });
        setSelectedColumn(() => {
            const initialValue: { [index: number]: string } = {};
            updatedColumns.forEach(item => {
                if (item.isIncluded) {
                    initialValue[item.id] = item.value;
                }
            });
            return initialValue;
        });
        setColumnData(updatedColumns);
        setPossibleColumnData(updatedColumns);
        setCheckedColumnItems({});
        setShowAdditionalColumnSelection(false);
    }
    const HandleAddColumnList = () => {
        setShowAdditionalColumnSelection(true);
    }
    const HandleCancelAddColumn = () => {
        setShowAdditionalColumnSelection(false);
    }
    useEffect(() => {
    }, [selectedColumn]);
    //Results
    const [modalIsOpenCSV, setModalIsOpenCSV] = useState(false);
    const [modalIsOpenZip, setModalIsOpenZip] = useState(false);
    const [modalIsOpenPDF, setModalIsOpenPDF] = useState(false);

    const handleCorpCode = () => {
        console.log(data_transactions)
        let startDateFormatted = dateformat(startDate, "yyyy-mm-dd");
        let endDateFormatted = dateformat(endDate, "yyyy-mm-dd");

        let selectedIds: number[] = [];
        if (selectedRows.length > 0) {
            let transactions = data_transactions.map((transaction, index) => selectedRows[index] ? transaction : null);
            transactions.forEach(transaction => {
                if (transaction !== null) {
                    selectedIds.push(transaction.id);
                }
            });
        }

        const corpCodeFilter = corpcode.value;

        LockboxesTransactions.createCorpCodeReport(selectLockboxID, {
            startDate: startDateFormatted,
            endDate: endDateFormatted,
            ...(filterQuery && { filters: filterQuery }),
            ...(detailFilterQuery && { detailFilters: detailFilterQuery }),
            ...(corpCodeFilter && { corpCode: corpcode.value }),
            includeChecks: true,
            includeDocuments: true,
            fileType: "csv",
            selectedTransactions: selectedIds

        });

        setModalIsOpenCSV(true);
    }
    //toCSV
    const handleCSV = () => {
        let startDateFormatted = dateformat(startDate, "yyyy-mm-dd");
        let endDateFormatted = dateformat(endDate, "yyyy-mm-dd");

        let selectedIds: number[] = [];
        if (selectedRows.length > 0) {
            let transactions = data_transactions.map((transaction, index) => selectedRows[index] ? transaction : null);
            transactions.forEach(transaction => {
                if (transaction !== null) {
                    selectedIds.push(transaction.id);
                }
            });
        }


        let columnOrder : string[] = [];
        if (columnData.length  >0)
        {         
            columnData.forEach(column => {


                 columnOrder.push(column.label)
                 
            });
        }

        const corpCodeFilter= corpcode.value;

        LockboxesTransactions.createReport(selectLockboxID, {
            startDate: startDateFormatted,
            endDate: endDateFormatted,
            ...(filterQuery && { filters: filterQuery }),
            ...(detailFilterQuery && { detailFilters: detailFilterQuery }),
            ...(corpCodeFilter && corpCodeFilter !== 'select corp code' && { corpCode: corpcode.value }),
            includeChecks: true,
            includeDocuments: true,
            fileType: "csv",
            selectedTransactions: selectedIds,
            ColumnOrder : columnOrder

        });

        setModalIsOpenCSV(true);
    }
    //toPDF
    const handlePDF = () => {
        let startDateFormatted = dateformat(startDate, "yyyy-mm-dd");
        let endDateFormatted = dateformat(endDate, "yyyy-mm-dd");

        let selectedIds: number[] = [];
        if (selectedRows.length > 0) {
            let transactions = data_transactions.map((transaction, index) => selectedRows[index] ? transaction : null);
            transactions.forEach(transaction => {
                if (transaction !== null) {
                    selectedIds.push(transaction.id);
                }
            });
        }

        const corpCodeFilter= corpcode.value;

        LockboxesTransactions.createReport(selectLockboxID, {
            startDate: startDateFormatted,
            endDate: endDateFormatted,
            ...(filterQuery && { filters: filterQuery }),
            ...(detailFilterQuery && { detailFilters: detailFilterQuery }),
            ...(corpCodeFilter && corpCodeFilter !== 'select corp code' && { corpCode: corpcode.value }),
            includeChecks: true,
            includeDocuments: true,
            fileType: "pdf",
            selectedTransactions: selectedIds
        });

        setModalIsOpenPDF(true);
    }
    //to zip
    const handleZip = () => {
        let startDateFormatted = dateformat(startDate, "yyyy-mm-dd");
        let endDateFormatted = dateformat(endDate, "yyyy-mm-dd");

        let selectedIds: number[] = [];
        if (selectedRows.length > 0) {
            let transactions = data_transactions.map((transaction, index) => selectedRows[index] ? transaction : null);
            transactions.forEach(transaction => {
                if (transaction !== null) {
                    selectedIds.push(transaction.id);
                }
            });
        }

        const corpCodeFilter= corpcode.value;

        LockboxesTransactions.createReport(selectLockboxID, {
            startDate: startDateFormatted,
            endDate: endDateFormatted,
            ...(filterQuery && { filters: filterQuery }),
            ...(detailFilterQuery && { detailFilters: detailFilterQuery }),
            ...(corpCodeFilter && corpCodeFilter !== 'select corp code' && { corpCode: corpcode.value }),
            includeChecks: true,
            includeDocuments: true,
            fileType: "zip",
            selectedTransactions: selectedIds
        });

        setModalIsOpenZip(true);
    }
    const selfCloseFunction = () => {
        setModalIsOpenCSV(false);
        setModalIsOpenPDF(false);
        setModalIsOpenZip(false);
    }
    useEffect(() => {
    }, [columnData]);
    const handleSelectRow = (index: number) => {
        var selRows = [...selectedRows];
        selRows[index] = !selRows[index];
        setSelectedRows(selRows);
        if (selRows.some((element: boolean) => !element)) {
            setCheckedAll(false);
        }
        else {
            setCheckedAll(true);
        }
    }
    const handleSelectAll = () => {
        setCheckedAll(!checkedAll);
        if (checkedAll) {
            var setAllRowsFalse = selectedRows.map((function () { return !1 }));
            setSelectedRows(setAllRowsFalse);
        }
        else {
            var setAllRowsTrue = selectedRows.map((function () { return !0 }));
            setSelectedRows(setAllRowsTrue);
        }
    }
    const handleViewTransactionFile = async (row: any) => {
        setTransactionId(row.transactionId);
        let result = await BatchdataDetail.details(row.batchId);
        if (result !== undefined) {
            setData_batch_detail(result.items);
            setTransaction_current_page(row.batchItemSequenceNumber - 1);
            setBatchId(row.batchId);
            navigate(`/transaction-detail/${row.transactionId}`);
        }
    }
    //Reports
    const [modalIsOpenDelete, setModalIsOpenDelete] = useState(false);

    const [reports, setReports] = useState<Report[]>([]);

    const refreshReportTable = async () => {
        setIsReportsReloading(true);
        getReports()
            .then(reports => setReports(reports))
            .finally(() => setIsReportsReloading(false));
    }


    const handleDownload = (reportId: number) => {
        var report = reports.filter(r => r.id === reportId)[0];
        if (report) {
            if (report.fileType === "Csv") {
                downloadReport(report.reportUrl!);
            } else if (report.fileType === "Pdf") {
                downloadReport(report.reportUrl!);
            } else {
                downloadReport(report.reportUrl!);
            }
        }
    }

    const handleOnChangeFileType = (fileVal: string) => {
        setDownloadObject({ ...downloadObject, fileType: fileVal });
    }

    const handleDownloadCorpCodeReport = () => {
        let selectedTransactions = data_transactions
            .filter((_, index) => selectedRows[index])
            .map(value => value.serialNumber);
        if (selectedTransactions.length > 0) {
                handleCorpCode();
        } else {
            setTransactionNotSelected(true);
        }
    }

    const handleDownloadTransactions = () => {
        let selectedTransactions = data_transactions
            .filter((_, index) => selectedRows[index])
            .map(value => value.serialNumber);
        if (selectedTransactions.length > 0) {
            console.log('downloadObject ', downloadObject);
            if (downloadObject.fileType === "PDF") {
                handlePDF();
            } else if ((downloadObject.fileType === "Zip")) {
                handleZip();
            } else if ((downloadObject.fileType === "CSV")) {
                handleCSV();
            }
        } else {
            setTransactionNotSelected(true);
        }
    }

    const handleDelete = (reportId: number) => {
        setModalIsOpenDelete(true);
        setReportIdToDelete(reportId);
    }
    const handleConfirmDelete = () => {
        deleteReport(reportIdToDelete).then(() => {
            setModalIsOpenDelete(false);
            setReports(reports => reports.filter(r => r.id !== reportIdToDelete));
            setReportIdToDelete(0);
        });
    }



    return (
        <main>
            <ClientHeader showClientName={true} showBreadcrumbs={false} />
            <h4 ref={initialTextRef} tabIndex={-1} className='search-and-report-title heading-focus-text'>Search and reports</h4>
            <span aria-live='polite' className='announcement-text'>{liveAnnouncementText}</span>
            <ModalsContainer noGap>
                <CustomModal okType ModalText='Please select a Lockbox ID to start your search.' ModalTitle='Required information missing' ModalIsOpen={showValidationLockboxID} CloseModal={() => { setShowValidationLockboxID(false) }} />
                <CustomModal okType ModalText='Please enter a date range up to 90 days.' ModalTitle='Incorrect date range' ModalIsOpen={showValidationDateDiff} CloseModal={() => { setShowValidationDateDiff(false) }} />
                <CustomModal routeType ModalText='Your report has been requested and will be available in Reports. This could take a few minutes.' ModalTitle='Request submitted' selfClose={selfCloseFunction} selfCloseSearchAndReports={true} ModalIsOpen={modalIsOpenCSV} OpenModal={handleCSV} CloseModal={() => { setModalIsOpenCSV(false) }} />
                <CustomModal routeType ModalText='Your report has been requested and will be available in Reports. This could take a few minutes.' ModalTitle='Request submitted' selfClose={selfCloseFunction} selfCloseSearchAndReports={true} ModalIsOpen={modalIsOpenPDF} OpenModal={handlePDF} CloseModal={() => { setModalIsOpenPDF(false) }} />
                <CustomModal routeType ModalText='Your report has been requested and will be available in Reports. This could take a few minutes.' ModalTitle='Request submitted' selfClose={selfCloseFunction} selfCloseSearchAndReports={true} ModalIsOpen={modalIsOpenZip} OpenModal={handleZip} CloseModal={() => { setModalIsOpenZip(false) }} />
                <CustomModal okType ModalText='Please select one or more transactions before proceeding to download.' ModalTitle='No transactions selected' ModalIsOpen={transactionNotSelected} CloseModal={() => { setTransactionNotSelected(false) }} />
            </ModalsContainer>
            <SearchNavigationTabs bottomControl1 bottomControl2 HandleViewSearch={HandleLockboxTransactionSearch} Tab1='Start search' Tab2='Customize search results' Tab3='View search results' Tab4='Reports'
                Content1={<div className=''>
                    <SearchSection title='Search by Lockbox ID'>
                        <Select
                            handleOnChange={HandleOnChangeSelectLockboxId}
                            value={selectLockboxID}
                            inputId='search-by-lockbox-id'
                            label='Select your Lockbox ID (required)'
                            name='search-by-lockbox-id'
                            options={lockbox_options}
                            size='small'
                            classes='width-236'
                        />
                        <div className="left-24-gap-filters width-226" >
                            <CalendarDateInput startDateWithEndPopover={true} label='Start date' selected={startDate} onChange={HandleOnChangeStartDate} name='input-start-date' id='input-start-date' />
                        </div>
                        <div className="left-24-gap-filters width-226">
                            <CalendarDateInput label='End date' selected={endDate} onChange={HandleOnChangeEndDate} name='input-end-date' id='input-end-date' showPopover={true} popoverText='View up to 90 days of information at a time.' />
                        </div>
                        {corpcode.options && corpcode.options.length > 1 && <Select
                            handleOnChange={HandleOnChangeSelectCorpCode}
                            value={corpcode.value}
                            inputId='search-by-corp-code'
                            label='Corp Code'
                            name='search-by-lockbox-id'
                            options={corpcode.options}
                            size='small'
                            classes='left-24-gap-filters width-236'
                        />}
                    </SearchSection>
                    <SearchSection title='Search by batch number'>
                        <div className='search-text-input-226'>
                            <TextInput
                                label="From batch number"
                                initialValue={selectBatch.fromBatch}
                                size='small'
                                handleOnChange={HandleOnChangeFromBatch}
                                type="text"
                            />
                        </div>
                        <div className='search-text-input-226 left-24-gap-filters'>
                            <TextInput
                                label="To batch number"
                                initialValue={selectBatch.toBatch}
                                size='small'
                                handleOnChange={HandleOnChangeToBatch}
                                type="text"
                            />
                        </div>
                    </SearchSection>
                    {!transactionDetailsLoading ?
                        <SearchSection transactionSection title='Search by transaction detail'>
                            <>
                                <AdditionalOptionsSelection ModalIsOpen={showAdditionalSelection} CloseModal={HandleCancelSelection} selectionClickMethod={HandleAdditionalSelection}>
                                    {transactionOptions.filter(item => item.isDeleted === true).map(item => {
                                        return <div className='additional-options-checkboxes'>
                                            <Checkbox
                                                isChecked={checkedItems[item.value] || !item.isDeleted}
                                                handleOnChange={HandleOnChangeAdditionalSelection}
                                                label={item.label}
                                                aria-labelledby="Select"
                                                name={item.value}
                                            />
                                        </div>
                                    })}
                                </AdditionalOptionsSelection>
                                <div>
                                    {transactionOptions.map((transaction, index) => {
                                        return <>
                                            {!transaction.isDeleted && (
                                                <div className='transaction-detail-section' key={index}>
                                                    <p className='transaction-detail-label'>{transaction.label}</p>
                                                    <div className='left-24-gap-filters'>
                                                        <Select
                                                            handleOnChange={(e: Event) => HandleOnChangeSelectOperation(e, transaction.value)}
                                                            inputId={transaction.label}
                                                            label='Operation'
                                                            name={transaction.value}
                                                            options={operationOptions.filter(operation => operation.notApplicableTo !== transaction.type).map(el => el)}
                                                            size='small'
                                                            classes='width-236'
                                                            value={selectValues[transaction.value] || ''}
                                                        />
                                                    </div>
                                                    {
                                                        (selectValues[transaction.value] === 'range' && transaction.type === 'Currency') ?
                                                            <>
                                                                <div className='search-text-input-212 left-24-gap-filters'>
                                                                    <CurrencyInput
                                                                        label="From"
                                                                        initialValue={textInputs[transaction.value]?.[0] || ''}
                                                                        name={transaction.value}
                                                                        size='small'
                                                                        handleOnChange={(e: Event) => HandleTextInputChange(e, transaction.value, 0)}
                                                                    />
                                                                </div>
                                                                <div className='search-text-input-214 left-24-gap-filters'>
                                                                    <CurrencyInput
                                                                        label="To"
                                                                        initialValue={textInputs[transaction.value]?.[1] || ''}
                                                                        name={transaction.value}
                                                                        size='small'
                                                                        handleOnChange={(e: Event) => HandleTextInputChange(e, transaction.value, 1)}
                                                                    />
                                                                </div>
                                                            </>
                                                            : (selectValues[transaction.value] !== 'range' && transaction.type === 'Currency') ?
                                                                <div className='search-text-input left-24-gap-filters input-value-box '>
                                                                    <CurrencyInput
                                                                        label="Value"
                                                                        initialValue={textInputs[transaction.value]?.[0] || ''}
                                                                        name={transaction.value}
                                                                        size='small'
                                                                        handleOnChange={(e: Event) => HandleTextInputChange(e, transaction.value, 0)}
                                                                    />
                                                                </div>
                                                                : (selectValues[transaction.value] === 'range') ?
                                                                    <>
                                                                        <div className='search-text-input-212 left-24-gap-filters'>
                                                                            <TextInput
                                                                                label="From"
                                                                                initialValue={textInputs[transaction.value]?.[0] || ''}
                                                                                size='small'
                                                                                name={transaction.value}
                                                                                handleOnChange={(e: Event) => HandleTextInputChange(e, transaction.value, 0)}
                                                                                type="text"
                                                                            />
                                                                        </div>
                                                                        <div className='search-text-input-214 left-24-gap-filters'>
                                                                            <TextInput
                                                                                label="To"
                                                                                initialValue={textInputs[transaction.value]?.[1] || ''}
                                                                                size='small'
                                                                                name={transaction.value}
                                                                                handleOnChange={(e: Event) => HandleTextInputChange(e, transaction.value, 1)}
                                                                                type="text"
                                                                            />
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <div className='left-24-gap-filters input-name-box'>
                                                                        <TextInput
                                                                            label="Value"
                                                                            initialValue={textInputs[transaction.value]?.[0] || ''}
                                                                            size='small'
                                                                            name={transaction.value}
                                                                            handleOnChange={(e: Event) => HandleTextInputChange(e, transaction.value, 0)}
                                                                            type="text"
                                                                        />
                                                                    </div>
                                                    }
                                                    <div className='top-gap-32 left-24-gap-filters'>
                                                        <Button
                                                            clickMethod={() => { HandleDeleteTransactionList(transaction.id) }}
                                                            text="Delete"
                                                            variant="secondary"
                                                            icon='IconTrash'
                                                            size="medium"
                                                        />
                                                    </div>
                                                </div>)
                                            }
                                        </>
                                    })
                                    }

                                </div>
                                {transactionOptions.filter(item => item.isDeleted).length > 0 && (
                                    <div className='top-gap-24'>
                                        <Button
                                            clickMethod={HandleAddTransactionList}
                                            text="Add another transaction detail(s)"
                                            variant="text-primary"
                                            icon='IconPlus'
                                        />
                                    </div>
                                )}
                            </>
                        </SearchSection>
                        :
                        <Loader />
                    }
                </div>}
                Content2={<div className='customize-section'>
                    <AdditionalOptionsSelection columns ModalIsOpen={showAdditionalColumnSelection} CloseModal={HandleCancelAddColumn} selectionClickMethod={HandleAddColumnSelection}>
                        {columnData.filter(item => item.isIncluded === false).map(item => {
                            return <div className='additional-options-checkboxes'>
                                <Checkbox
                                    isChecked={checkedColumnItems[item.value] || item.isIncluded}
                                    handleOnChange={HandleOnChangeAddColumnSelection}
                                    label={item.label}
                                    aria-labelledby="Select"
                                    name={item.value}
                                />
                            </div>
                        })}
                    </AdditionalOptionsSelection>
                    <p className='column-section-title'>Choose the columns you want to include in your search results.</p>
                    <div className='' >
                        {columnData.filter(el => el.isIncluded).map((el, index) => {
                            return <>{(el.value === 'Select' || el.value === 'View') ?
                                <></>
                                :
                                <div>
                                    <div className='column-section tabs-content-input-labels' key={el.id}>
                                        <Select
                                            handleOnChange={(e: Event) => HandleOnChangeSelectColumn(e, el.id,index)}
                                            inputId={el.label}
                                            label={`Column ${index + 1}`}
                                            name='column-customization'
                                            options={possibleColumns.filter(item=>  item.isIncluded)}
                                            size='small'
                                            classes='width-322'
                                            value={el.value || ''}
                                        // isDisabled={el.isIncluded}
                                        />
                                        <div className='top-gap-32 left-24-gap-filters'>
                                            <Button
                                                classes=""
                                                clickMethod={() => { HandleDeleteColumnList(selectedItemId, el.id, index) }}
                                                text="Delete"
                                                variant="secondary"
                                                icon='IconTrash'
                                                size="medium"
                                            />
                                        </div>
                                    </div>
                                    {index === columnData.length ? <></> : <div className='column-separator'></div>}
                                </div>
                            }
                            </>
                        })
                        }
                    </div>
                    {columnData.filter(item => !item.isIncluded).length > 0 &&
                        <div className='top-gap-24'>
                            <Button
                                clickMethod={HandleAddColumnList}
                                text="Add another column"
                                variant="text-primary"
                                icon='IconPlus'
                            />
                        </div>
                    }
                </div>}
                Content3={<div className=''>
                    <div className='loader-section'>
                        <Loader transactionLoader isLoading={loadingSearch} />
                        <div className={loadingSearch ? 'custom-loader-hidden' : 'custom-loader-visible'}>
                            {(data_transactions && data_transactions.length > 0) ?
                                <>
                                    <div className='download-report-container download-search-and-Reports'>
                                        {(corpcode.value !== "select corp code") ?
                                            <>
                                                <Button
                                                    text="Download Corp Code report as CSV"
                                                    ariaLabel="Download corp code"
                                                    dataTestId="download-corp-code-report-menu"
                                                    variant="alternate"
                                                    size="medium"
                                                    classes="highlighted-active"
                                                    clickMethod={() => { handleDownloadCorpCodeReport() }}
                                                />
                                                
                                            </>
                                            :
                                            <>
                                            </>
                                        }
                                        
                                        <DropdownMenu>
                                            <DropdownTrigger
                                                text="Download transactions"
                                                ariaLabel="Download transactions menu"
                                                dataTestId="download-transactions-menu"
                                                variant="alternate"
                                                size="medium"
                                                classes="highlighted-active"
                                            />
                                            <DropdownMenuContent>
                                                <p className='title'>Choose download options</p>

                                                {errorMessagesSummary.length !== 0 &&
                                                    <ErrorSummary
                                                        errors={errorMessagesSummary}
                                                        ref={errorSummaryRef}
                                                    />
                                                }
                                                <div className='dropdown-divider'></div>
                                                <div className='radio-groups'>
                                                    <RadioGroup
                                                        groupFormControlId="fileSelect"
                                                        legendLabel="File type"
                                                        name="radio-group-file"
                                                        size="small"
                                                        errorMessages={["Select a file type to continue."]}
                                                        isValid={isvalidFile}
                                                        radioItems={[

                                                            {
                                                                handleOnChange: () => { handleOnChangeFileType("PDF") },
                                                                inputId: 'radio-filetype-pdf',
                                                                isChecked: downloadObject.fileType === "PDF",
                                                                label: 'PDF',
                                                                value: "PDF",
                                                                size: 'small'
                                                            },
                                                            {
                                                                handleOnChange: () => { handleOnChangeFileType("Zip") },
                                                                inputId: 'radio-filetype-zip',
                                                                isChecked: downloadObject.fileType === "Zip",
                                                                label: 'Zip file',
                                                                value: "Zip",
                                                                size: 'small'
                                                            },
                                                            {
                                                                handleOnChange: () => { handleOnChangeFileType("CSV") },
                                                                inputId: 'radio-filetype-csv',
                                                                isChecked: downloadObject.fileType === "CSV",
                                                                label: 'CSV file',
                                                                value: "CSV",
                                                                size: 'small'
                                                            },
                                                        ]}
                                                    />
                                                </div>
                                                <Button
                                                    classes="download-button lockbox-search-zero-state"
                                                    size="medium"
                                                    text="Download"
                                                    clickMethod={() => { handleDownloadTransactions() }}
                                                />
                                            </DropdownMenuContent>
                                        </DropdownMenu>
                                    </div>

                                    <div className='view-results-table'>
                                        <Table
                                            ariaDescribedBy={null}
                                            caption='Search results'
                                            classes=""
                                            columnData={[...[{
                                                rowCellTableRowComponentsArrayIndex: 1,
                                                headerId: 'selectAll',
                                                type: 'component',
                                                headerLabel: 'Select all',
                                                headerCellTableRowComponentsArrayIndex: 0,
                                                disableSorting: true,
                                                value: 'Select',
                                                label: 'Select',
                                                id: 1
                                            },
                                            {
                                                rowCellTableRowComponentsArrayIndex: 2,
                                                disableSorting: true,
                                                headerId: 'filename',
                                                headerLabel: 'View',
                                                type: 'component',
                                                value: 'View',
                                                label: 'View',
                                                id: 2
                                            }], ...columnData.filter(el => el.isIncluded).map(el => el)]}
                                            data={data_transactions}
                                            showSupplementalStatus
                                            supplementalStatus={`${selectedRows.filter(val => val === true).length} of ${data_transactions.length} ${data_transactions.length > 1 ? 'rows' : 'row'} selected for download`}
                                            tableRowComponents={[
                                                (props: any) => (
                                                    <div className='table-select-all'>
                                                        <span>Select all</span>
                                                        <Checkbox
                                                            isChecked={checkedAll}
                                                            handleOnChange={() => handleSelectAll()}
                                                            label=""
                                                            aria-labelledby="Select all"
                                                        />
                                                    </div>
                                                ),
                                                (props: any) => (
                                                    <div className='center-select'>
                                                        <Checkbox
                                                            isChecked={selectedRows[props.row.index]}
                                                            handleOnChange={() => handleSelectRow(props.row.index)}
                                                            label=""
                                                            aria-labelledby="Select"
                                                        />
                                                    </div>
                                                ),
                                                (props: any) => (
                                                    <Button
                                                        variant="text-primary"
                                                        clickMethod={() => handleViewTransactionFile(props.row.original)}
                                                        ariaLabel={`Transaction ${props.row.original.transactionId}`}
                                                        customIcon={IconSearch}
                                                        iconPosition='left'
                                                    />
                                                ),
                                                (props: any) => {
                                                    const columnId= props.column.id;
                                                    return (<div>
                                                        {props.row.original[columnId].map((recipient: string, index: number) => (
                                                            <p key={index}>
                                                                {recipient}
                                                            </p>
                                                        ))}
                                                    </div>)
                                                },
                                            ]}
                                        />
                                    </div>
                                </>
                                :
                                <p className='zero-state'>No result found. Please set search preferences and try again.</p>
                            }
                        </div>
                    </div>
                </div>}
                Content4={<div className=''>
                    <div className='download-report-container refresh-container'>
                        <Button
                            clickMethod={refreshReportTable}
                            isLoading={isReportsReloading}
                            loadingText="Refreshing"
                            text='Refresh list'
                            variant='secondary'
                            size='small'
                            customIcon={IconRefresh}
                            iconPosition='right'
                        />
                    </div>
                    <div className="bottom-gap-10">
                        <p className="info-text__18 ">Reports are available for download for 7 calendar days.</p>
                    </div>
                    <ModalsContainer noGap>
                        <CustomModal yesOrNoType ModalIsOpen={modalIsOpenDelete} CloseModal={() => { setModalIsOpenDelete(false) }} noText='No, I do not want to delete it' yesText='Yes, I want to delete it' yesClickMethod={handleConfirmDelete} ModalText='Are you sure you want to delete this report? The information will no longer be available.' ModalTitle='Deleting report' />
                    </ModalsContainer>
                    {!loading ?
                    <div className='loader-section'>
                        {(reports.length > 0) ?
                            <div className='lockbox-reports-table'>
                                <Table
                                    ariaDescribedBy={null}
                                    caption='Reports'
                                    classes=""
                                    columnData={
                                        [
                                            {
                                                headerId: 'requestedOn',
                                                headerLabel: 'Requested date and time',
                                                rowCellTableRowComponentsArrayIndex: 0,
                                                type: 'component'
                                            },
                                            {
                                                headerId: 'status',
                                                headerLabel: 'Status'
                                            },
                                            {
                                                headerId: 'fileType',
                                                headerLabel: 'File type',
                                                rowCellTableRowComponentsArrayIndex: 1,
                                                type: 'component'
                                            },
                                            {
                                                headerId: 'reportType',
                                                headerLabel: 'Report type',
                                                rowCellTableRowComponentsArrayIndex: 2,
                                                type: 'component'
                                            },
                                            {
                                                rowCellTableRowComponentsArrayIndex: 3,
                                                disableSorting: true,
                                                headerId: 'action',
                                                type: 'component',
                                                headerLabel: 'Download or delete'
                                            }
                                        ]
                                    }
                                    data={reports}
                                    tableRowComponents={[
                                        (props: any) => (
                                            <DateDisplay format="fullDate" date={props.row.original.requestedOn} />
                                        ),
                                        (props: any) => (
                                            <span>{props.row.original.fileType.toUpperCase()}</span>
                                        ),
                                        (props: any) => {
                                            switch (props.row.original.reportType) {
                                                case "BatchDetail":
                                                    return <span>Batch detail</span>
                                                case "BatchSummary":
                                                    return <span>Batch summary</span>
                                                case "LockboxSummary":
                                                    return <span>Lockbox summary</span>
                                                case "Remitter":
                                                    return <span>Remitter</span>
                                                case "ReportingSummary":
                                                    return <span>Reporting summary</span>
                                                case "TransactionDetail":
                                                    return <span>Transaction detail</span>
                                                case "ExceptionDailyDetailSummary":
                                                    return <span>Exception daily detail summary</span>
                                                case "ExceptionSummary":
                                                    return <span>Exception summary</span>
                                                case "ExceptionBatchSummary":
                                                    return <span>Exception batch summary</span>                                                 
                                                case "ExceptionDetailSummary":
                                                    return <span>Exception detail summary</span>
                                                case "DailyExceptionSummary":
                                                    return <span>Daily exception summary</span>
                                                case "ExceptionDailyDetail":
                                                    return <span>Daily exception detail summary</span>
                                                case "ExceptionBatchDetail":
                                                    return <span>Exception batch detail summary</span>
                                                case "UserEntitlement":
                                                    return <span>User entitlement</span>
                                                default:
                                                    return <span>{props.row.original.reportType}</span>
                                            }
                                        },
                                        (props: any) => (
                                            <div className='report-actions'>
                                                {props.row.original.status === "Processed" &&
                                                    <div>
                                                        <span className='action-icon'><IconDownload style={{ color: '#007C8C' }} width="20" height="20" /></span>
                                                        <Button
                                                            clickMethod={() => handleDownload(props.row.original.id)}
                                                            text="Download"
                                                            variant="text-primary"
                                                        /><span className='action-separator'> | </span>
                                                    </div>
                                                }

                                                    <span className='action-icon'><IconTrash style={{ color: '#007C8C' }} width="18" height="18" /></span>
                                                    <Button
                                                        clickMethod={() => handleDelete(props.row.original.id)}
                                                        text="Delete"
                                                        variant="text-primary"
                                                    />
                                                </div>
                                            ),
                                        ]}
                                    />
                                </div>
                                :
                                <></>
                            }
                            {reports.length === 0 && (<div>
                                <p className='zero-state'>You have no reports right now.</p>
                            </div>)}
                        </div>
                        :
                        <Loader />
                    }
                </div>
                }
            />

        </main>);
}

export default SearchAndReports;
