/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Checkbox, Loader, Select } from '@ux/balance-react';
import dateformat from "dateformat";
import { FormEvent, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom-v6';
import CorrespondenceData from '../API_Integrations/Correspondence';
import Lockbox from '../API_Integrations/Lockbox';
import CalendarDateInput from '../components/CalendarDateInput';
import ClientHeader from '../components/ClientHeader';
import CustomModal from '../components/CustomModal';
import EmptyState from '../components/EmptyState';
import ModalsContainer from '../components/ModalsContainer';
import Table from '../components/Table';
import { ILockboxCorrespondence, ILockbox_options } from '../interfaces/ICorrespondence';
import { ILockbox, ILockbox_req_body } from '../interfaces/iLockBox';
import { isDateWithinRange } from '../utils/use-date-calculator';
import { useFocusOnElemnt } from '../utils/use-focus-on-element';
import { useTitle } from '../utils/use-title';
import { useRepeatAnnouncement } from '../utils/use-repeat-live-announcement';

function Correspondence() {
    useTitle("Correspondence");
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedLockbox, setSelectedLockbox] = useState("");
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [isLoading, setIsLoading] = useState(false);
    const [showDownloadModal, setShowDownloadModal] = useState(false);
    const [showDateRangeValidationModal, setShowDateRangeValidationModal] = useState(false);
    const [showLockboxIdValidationModal, setShowLockboxIdValidationModal] = useState(false);
    const [correspondenceData, setCorrespondenceData] = useState<ILockboxCorrespondence[]>([]);
    const [selectedRows, setSelectedRows] = useState(Array(correspondenceData?.length ?? 0).fill(false));
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [liveAnnouncementText, setLiveAnnouncementText] = useState("");
    const initialTextRef = useRef<HTMLHeadingElement | null>(null);

    useFocusOnElemnt(initialTextRef);

    const [lockbox_options, setLockbox_options] = useState<ILockbox_options[]>([]);
    const lockbox_list = [
        {
            isDisabled: true,
            label: 'Select',
            value: ''
        },
    ];
    const handleOnChangeSelectLockbox = (event: Event) => setSelectedLockbox((event.target as HTMLInputElement).value);

    const handleLockboxSearch = async () => {
        

        // Validate that the lockbox is selected
        if (!selectedLockbox) {
            setShowLockboxIdValidationModal(true);
            setLiveAnnouncementText("");
            setIsLoading(false);
            return;
        }

        // Validate that the date is in an acceptable range
        const isValidRange = isDateWithinRange(startDate, endDate);
        if (!isValidRange) {
            setShowDateRangeValidationModal(true);
            setLiveAnnouncementText("");
            setIsLoading(false);
            return;
        }

        // Set the search Parameters
        setSearchParams({
            lockboxCode: selectedLockbox,
            startDate: dateformat(startDate, "yyyy-mm-dd"),
            endDate: dateformat(endDate, "yyyy-mm-dd")
        });
    }

    useEffect(() => {
        let lockboxCode = searchParams.get("lockboxCode");
        if (lockboxCode !== null) {
            getLockboxCorrespondence();
        }       
    }, [searchParams]);

    useEffect(() => {
        const keyDownHandler = (e: KeyboardEvent) => {
            if (e.key === 'Enter') {
                e.preventDefault();
                const targetedElement = document.activeElement as HTMLElement;
                const searchForm = document.getElementById('correspondence-search-form') as HTMLFormElement | null;
                if (searchForm && searchForm.checkValidity() && (targetedElement.classList.contains('correspondence-search-button') || targetedElement.tagName === 'BODY')) {
                    handleLockboxSearch();
                }
                if(targetedElement.tagName === 'BUTTON' && !targetedElement.classList.contains('correspondence-search-button')) {
                    targetedElement.click();
                }
            }
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [handleLockboxSearch]);

    const getLockboxData = async (ReqBody: ILockbox_req_body) => {
        let result = await Lockbox.listClientLockbox(ReqBody)
        if (result !== undefined) {
            return result;
        }
    }
    const getLockboxOptions = (options: ILockbox[]) => {
        let LockboxOptions = options.map(lockbox => ({ label: lockbox.code, value: lockbox.code }));
        setLockbox_options([...lockbox_list, ...LockboxOptions]);
        return LockboxOptions;
    };

    let announcement  = "Loading, please wait.";
    const repeatLoadingAnnouncement = () => {
        setLiveAnnouncementText(announcement);
        announcement += '.';
    };


    useRepeatAnnouncement({repeatLoadingAnnouncement, loadingSearch});

    const getLockboxCorrespondence = async () => {
        if (selectedLockbox) {
            setLoadingSearch(true);
            setLiveAnnouncementText("Loading, please wait.");
            let startDate = searchParams.get("startDate") ?? dateformat(new Date(), "yyyy-mm-dd");
            let endDate = searchParams.get("endDate") ?? dateformat(new Date(), "yyyy-mm-dd");
            try {
                let result = await CorrespondenceData.lockboxes(selectedLockbox, { startDate: startDate, endDate: endDate });
                setSelectedRows(Array(result?.length ?? 0).fill(false));
                setCorrespondenceData(result);
                if (result && result.length > 1) {
                    setLiveAnnouncementText(`${result.length} results found.`);     
                } else if (result && result.length === 1) {
                    setLiveAnnouncementText(`${result.length} result found.`);
                } else {
                    setLiveAnnouncementText("No results found.");
                }
            } catch (error) {
                console.log(error);
            } finally {
                setLoadingSearch(false);
            }
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const result = await getLockboxData({
                lockboxNumber: '',
                lockboxName: ''
            });
            getLockboxOptions(result);
        }
        fetchData();
    }, []);

    //download PDF
    const handleDownload = () => {
        let startDate = searchParams.get("startDate") ?? dateformat(new Date(), "yyyy-mm-dd");
        let endDate = searchParams.get("endDate") ?? dateformat(new Date(), "yyyy-mm-dd");
        let selectedBatches = correspondenceData
            .filter((_, index) => selectedRows[index])
            .map(value => value.batchId);

        setShowDownloadModal(true);
        CorrespondenceData.generateReport(selectedLockbox, { startDate: startDate, endDate: endDate, selectedCorrespondences: selectedBatches });
    }

    const handleSelectRow = (index: number) => {
        var selRows = [...selectedRows];
        selRows[index] = !selRows[index];
        setSelectedRows(selRows);
    }

    const handleSelectAll = () => {
        if (selectedRows.every(row => row)) {
            setSelectedRows(Array(correspondenceData?.length ?? 0).fill(false));
        } else {
            setSelectedRows(Array(correspondenceData?.length ?? 0).fill(true));
        }
    }

    const handleItemPDFSelection = (row: any) => {
        let startDate = searchParams.get("startDate") ?? dateformat(new Date(), "yyyy-mm-dd");
        let endDate = searchParams.get("endDate") ?? dateformat(new Date(), "yyyy-mm-dd");
        let batchId = row.batchId;

        setShowDownloadModal(true);
        CorrespondenceData.generateReport(selectedLockbox, { startDate: startDate, endDate: endDate, selectedCorrespondences: [batchId] });
    };

    return (
        <main>
            <ClientHeader showClientName={true} showBreadcrumbs={false} />
            <div>
                <h4 ref={initialTextRef} tabIndex={-1} className='text-style-heading-4 correspondence-title heading-focus-text'>
                    Correspondence
                </h4>
                {correspondenceData?.length > 0 && <p className='text-style-body-3'><span>Site: </span><strong>{correspondenceData[0].siteName}</strong></p>}
                <p className='text-style-heading-4 filter-title'>Filters</p>
            </div>
            <form className='lockbox-search-control' onSubmit={(e: FormEvent<HTMLFormElement>) => { e.preventDefault(); handleLockboxSearch(); }} id='correspondence-search-form'>
                <Select
                    label="Select your Lockbox ID (required)"
                    name="Select your Lockbox ID (required)"
                    size='small'
                    classes='width-322'
                    options={lockbox_options}
                    handleOnChange={handleOnChangeSelectLockbox}
                    value={selectedLockbox}
                />
                <div className="left-24-gap-filters">
                    <CalendarDateInput
                        startDateWithEndPopover={true}
                        label='From'
                        selected={startDate}
                        onChange={(date: Date) => setStartDate(date)}
                        name='Enter deposit start date'
                        id='input-start-date' />
                </div>
                <div className="left-24-gap-filters">
                    <CalendarDateInput
                        label='To'
                        selected={endDate}
                        onChange={(date: Date) => setEndDate(date)}
                        name='Enter deposit end date'
                        id='input-end-date'
                        showPopover={true}
                        popoverText='View up to 90 days of information at a time.' />
                </div>
                <div className='lockbox-search-button reporting-summary-button'>
                    <Button
                        classes="correspondence-search-button"
                        size="medium"
                        text="Search"
                        type="submit"
                        isLoading={loadingSearch}
                    />
                </div>
            </form>

            {isLoading &&
                <div className='loader-section correspondence-zero-state'>
                    <Loader isLoading />
                </div>
            }

            <span aria-live='polite' className='announcement-text'>{liveAnnouncementText}</span>

            {correspondenceData?.length > 0 &&
                (
                    <div className='lockbox-correspondence-table correspondence-zero-state'>
                        <div className="download-report-container">
                            <CustomModal
                                ButtonTitle='Download selected as PDF'
                                ModalIsOpen={showDownloadModal}
                                OpenModal={handleDownload}
                                CloseModal={() => setShowDownloadModal(false)}
                                ModalText='Your PDF has been requested and will be available in Reports.'
                                ModalTitle='Request submitted' />
                        </div>

                        <Table
                            ariaDescribedBy={null}
                            caption='Correspondence'
                            columnData={
                                [
                                    {
                                        rowCellTableRowComponentsArrayIndex: 1,
                                        headerId: 'lockboxId',
                                        type: 'component',
                                        headerLabel: 'Select all',
                                        headerCellTableRowComponentsArrayIndex: 0,
                                        disableSorting: true
                                    },
                                    {
                                        rowCellTableRowComponentsArrayIndex: 2,
                                        headerId: 'batchId',
                                        type: 'component',
                                        headerLabel: 'Item (PDF)'
                                    },
                                    {
                                        headerId: 'processDateFormatted',
                                        headerLabel: 'Process date'
                                    },
                                    {
                                        headerId: 'imageCountFormatted',
                                        headerLabel: 'Image count'
                                    }
                                ]
                            }
                            data={correspondenceData}
                            showSupplementalStatus
                            supplementalStatus={`${selectedRows.filter(val => val === true).length} of ${correspondenceData.length} ${correspondenceData.length > 1 ? 'rows' : 'row'} selected for download`}
                            tableRowComponents={[
                                (props: any) => (
                                    <div className='table-select-all'>
                                        <span>Select all</span>
                                        <Checkbox
                                            isChecked={selectedRows.every(item => item)}
                                            handleOnChange={handleSelectAll}
                                            label=""
                                            aria-labelledby="Select all"
                                        />
                                    </div>
                                ),
                                (props: any) => (
                                    <div className='center-select'>
                                        <Checkbox
                                            isChecked={selectedRows[props.row.index]}
                                            inputId={props.row.original.batchId}
                                            handleOnChange={() => handleSelectRow(props.row.index)}
                                            label=""
                                            aria-labelledby="Select"
                                        />
                                    </div>
                                ),
                                (props: any) => (
                                    <Button
                                        variant="text-primary"
                                        onClick={() => handleItemPDFSelection(props.row.original)}
                                        text={props.row.original.batchNumber}
                                    />
                                )
                            ]}
                        />
                    </div>
                )
            }

            {!isLoading && correspondenceData?.length === 0 &&
                <EmptyState
                    descriptionClassName="zero-state correspondence-zero-state"
                    description="No results found."
                />
            }

            <ModalsContainer>
                <CustomModal
                    okType
                    ModalText='Please select a Lockbox ID to start your search.'
                    ModalTitle='Required information missing'
                    ModalIsOpen={showLockboxIdValidationModal}
                    CloseModal={() => { setShowLockboxIdValidationModal(false) }}
                />
                <CustomModal
                    okType
                    ModalText='Please enter a date range up to 90 days.'
                    ModalTitle='Incorrect date range'
                    ModalIsOpen={showDateRangeValidationModal}
                    CloseModal={() => { setShowDateRangeValidationModal(false) }}
                />
            </ModalsContainer>
        </main>
    )
}

export default Correspondence;
