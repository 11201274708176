import axios from "axios";
import requests from "./apiRequest";

export interface User {
    id: number,
    username: string,
    firstName?: string,
    middleName?: string,
    lastName?: string,
    emailAddress?: string,
    isAdmin: boolean,
    isBanker: boolean
    clientCode: string,
    clientName: string,
    lastLogin: Date,
    status: string,
    canAddUsers: boolean,
    canModifyUsers: boolean,
    canChangeUserStatuses: boolean,
    canViewUsers: boolean,
    canManageBanners: boolean,
    canManageExceptions: boolean,
    canUnlockExceptions: boolean,
    allowedLockboxes: string[]
    allowedCorpCodes: string[]
    rowClasses: string
    createdOn?: Date
    modifiedOn?: Date
}

export interface CreateEditUser {
    clientCode: string
    username: string
    isAdmin: boolean
    isBanker?: boolean
    firstName: string
    middleName: string
    lastName: string
    emailAddress: string
    status?: string
    canAddUsers: boolean
    canModifyUsers: boolean
    canChangeUserStatuses: boolean
    canViewUsers: boolean
    canManageBanners: boolean
    canManageExceptions: boolean
    canUnlockExceptions: boolean
    lockboxCodes: string[]
    corpCodes: string[]
}

export interface EditUser {
    id: number
    clientCode: string
    username: string
    isAdmin: boolean
    isBanker: boolean
    firstName: string
    middleName: string
    lastName: string
    emailAddress: string
    status: string
    canAddUsers: boolean
    canModifyUsers: boolean
    canChangeUserStatuses: boolean
    canViewUsers: boolean
    canManageBanners: boolean
    canManageExceptions: boolean
    canUnlockExceptions: boolean
    allowedLockboxes: string[]
    allowedCorpCodes: string[]
}

export interface CreateUser {
    clientCode: string
    username: string
    isAdmin: boolean
    firstName: string
    middleName: string
    lastName: string
    emailAddress: string
    canAddUsers: boolean
    canModifyUsers: boolean
    canChangeUserStatuses: boolean
    canViewUsers: boolean
    canManageBanners: boolean
    canManageExceptions: boolean
    canUnlockExceptions: boolean
    lockboxCodes: string[]
    corpCodes: string[]
}

export interface UserEntitlementReportRequest {
    fileType: "Csv"
    isAdmin: boolean
}

export const getUsers = async (): Promise<User[]> => {
    let users = await requests.get("/users");
    return users;
}
export const getUser = async (id: number): Promise<EditUser> => {
    let users = await requests.get("/users/" + id);
    return users;
}

export const createUser = async (user: CreateUser) => {
        await axios.post("/users", user);
}
export const editUser = async (id: number, user: CreateEditUser) => {
        await axios.post("/users/" + id, user);
}

export const deleteUser = async (id: number) => {
    await requests.delete("/users/" + id);
}

export const undeleteUser = async (id: number) => {
    return await requests.patch(`/users/${id}/undelete`, {}); // Fixed URL
};

export const generateUserEntitlementReport = async (body: UserEntitlementReportRequest) => {
    await requests.post("/users/report", body);
}
