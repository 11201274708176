import CorpCodes from '../API_Integrations/CorpCodes';
import { getUser } from "../API_Integrations/users";
import { IUser } from "../interfaces/IUser";

export default async function setCorpCodeAndDrowndownValues(userType: IUser, corpCodeSetter: any) {
    let user = await getUser(userType.id);
    let allowedCorpCodes = user.allowedCorpCodes;
    let corpCodes: any[] = await CorpCodes.list();

    let allowedCorpCodeNames: string[] = [];

    corpCodes.forEach((item) => {
        if (allowedCorpCodes.includes(item.code)) {
            allowedCorpCodeNames.push(item.name); 
        }
    });

    if (allowedCorpCodeNames && allowedCorpCodeNames.length > 1) {
        let corpCodeOptions = allowedCorpCodeNames.map((name) => {
            let corpCode = corpCodes.find(corp => corp.name === name);
            return {
                label: name,
                value: corpCode ? corpCode.code : name
            };
        });

        corpCodeSetter({
            value: 'select corp code',
            options: [
                { label: 'Select', value: 'select corp code', isDisabled: true },
                { label: 'All Corp Codes', value: '' },
                ...corpCodeOptions
            ]
        });
    }
}
